<template>
    <layout-centered>
        <div class="p-4">
            <img class="d-block mx-auto mb-5" src="@/assets/img/Forklane-logo.webp" :alt="appTitle">

            <div v-if="!loading">
                <form @submit.prevent="onSignIn">
                    <div class="form-group">
                        <label for="email">{{ $t('email-address') }}</label>
                        <input id="email"
                            class="form-control"
                            type="email"
                            required="required"
                            :placeholder="$t('email')"
                            v-model="email">
                    </div>

                    <div class="form-group">
                        <label for="password">{{ $t('password') }}</label>
                        <input id="password"
                            class="form-control"
                            type="password"
                            required="required"
                            :placeholder="$t('password')"
                            v-model="password">
                    </div>

                    <div class="form-group">
                        <label for="language">{{ $t('language') }}</label>
                        <select id="language" class="form-control">
                            <option>{{ $t('english') }}</option>
                            <option>{{ $t('deutsch') }}</option>
                        </select>
                    </div>
                    <div class="form-group ml-1">
                        <router-link class="text-dark" :to="{ name: 'password-forgot'}">
                            {{ $t('forgot-password') }}
                        </router-link>
                    </div>
                    <button class="btn btn-block btn-primary" type="submit">{{ $t('sign-in') }}</button>
                </form>
            </div>

            <div v-if="loading">
                <component-spinner class="p-5" type="spinner-grow"/>
            </div>

            <div class="alert alert-danger alert-dismissible fade show mt-3" role="alert" v-if="!loading && error">
                {{ $t('invalid-email-or-password') }}
                <button type="button" class="close" data-dismiss="alert" :aria-label="$t('close')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </layout-centered>
</template>

<script>

import LayoutCentered from "@/layouts/LayoutCentered";
import ComponentSpinner from "@/components/ui/ComponentSpinner";

export default {
    name: "PageSignIn",
    components: { ComponentSpinner, LayoutCentered },
    computed: {
        appTitle() {
            return process.env.VUE_APP_TITLE;
        }
    },
    data() {
        return {
            loading: false,
            error: false,
            email: 'support@stina-global.com',
            password: 'STINA123'
        }
    },
    methods: {
        async onSignIn() {
            this.loading = true;

            await this.$store.dispatch('forkLane/loginNode')
            
            const payload = {
                username: this.email,
                password: this.password,
                nodeToken: this.$store.state.forkLane.node
            }
            this.$store.dispatch('forkLane/loginUser', payload)
                .then(result => {
                    Promise.resolve(result);
                    this.loading = false;
                })
                .catch(error => {
                    console.error(error);
                    Promise.reject(error);
                    this.loading = false;
                    this.error = true;
                });
        }
    }
}
</script>

<style scoped lang="scss">
#sign-in-outer {
}
#sign-in-inner {
    margin-top: 10em;
    min-width: 400px;
}
</style>